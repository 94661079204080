import Vue from "vue";
import VueRouter from "vue-router";

import Root from "../views/root/index.vue";
import Login from "../views/login/login.vue";
import Main from "../views/root/main.vue";
import NotFound from "../views/root/NotFound.vue";

import Module1 from "../views/Home/index.vue";
import Module2 from "../views/Work/index.vue";
import Module3 from "../views/Mine/index.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/", name: "Root", component: Root },
  { path: "/Login", name: "Login", component: Login },
  {
    path: "/Main",
    name: "Main",
    component: Main,
    children: [
      { path: "/Module1", name: "Module1", component: Module1 },
      { path: "/Module2", name: "Module2", component: Module2 },
      { path: "/Module3", name: "Module3", component: Module3 },
    ],
  },

  { path: "/NotFound", name: "NotFound", component: NotFound },
  // 所有未定义路由，全部重定向到404页，必须放在最后
  {
    path: "*",
    redirect: { name: "NotFound" },
  },
];

const router = new VueRouter({
  routes,
  // mode: 'hash',
  // base: process.env.BASE_URL,
  // /**
  //  * 路由跳转后页面滚动到顶部
  //  * 详见：https://router.vuejs.org/zh/guide/advanced/scroll-behavior.html
  //  */
  // scrollBehavior: () => ({ y: 0 }),
});

// import {getToken} from "@/utils/auth";
// // 路由前置守卫
// router.beforeEach((to, from, next) => {
//   const { meta, name } = to;
//   const { isLogin } = meta;
//   let token = getToken();

//   // token不存在时跳转非登录页，重定向到登录页
//   if (!token && name !== 'Login' && !isLogin) {
//     next({ name: 'Login' });
//   } else if (token && name === 'Login') {
//     next({ name: 'Module1' });
//   }
//   // 其他场景
//   else next();
// });

export default router;
