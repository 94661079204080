import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "vant/lib/index.css";
import "./config/vant/vant.js";
import './config/css/global.css'
import JhRefreshView from "./components/JhRefreshView/index.vue";

Vue.component("JhRefreshView", JhRefreshView);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
