import Vue from 'vue'
import { NavBar } from 'vant';
import { Tabbar, TabbarItem } from 'vant';
import { Tab, Tabs } from 'vant';
import { Icon } from 'vant';
import { Button } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Form } from 'vant';
import { Field } from 'vant';
import { PullRefresh } from 'vant';
import { List } from 'vant';
import { ActionSheet } from 'vant';
import { Dialog } from 'vant';
import { Toast } from 'vant';
import { Swipe, SwipeItem } from 'vant';
import { Search } from 'vant';
import { Grid, GridItem } from 'vant';
import { CountDown } from 'vant';
import { popup } from 'vant';

Vue.use(popup);
Vue.use(NavBar);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Icon);
Vue.use(Button);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Form);
Vue.use(Field);
Vue.use(PullRefresh);
Vue.use(List);
Vue.use(ActionSheet);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Search);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(CountDown);
