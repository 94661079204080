<script>

export default {
  data() {
    return {
      sms: '',
      showHot: false,
      messages: [],
      hotList: [
        {
          content: "货运资格证如何办理？",
          createBy: null,
          createTime: "2024-08-30 14:00:40",
          id: 72,
          info: "您好，根据《交通运输部关于修改〈道路运输从业人员管理规定〉的决定》（中华人民共和国交通运输部令2019年第18号）的有关规定，申请参加经营性道路客货运输驾驶员从业资格考试的人员，应当向其户籍地或者暂住地设区的市级道路运输管理机构提出申请，经考试合格后，取得《中华人民共和国道路运输从业人员从业资格证》。\n　　经营性道路货物运输驾驶员应当符合下列条件：（一）取得相应的机动车驾驶证；（二）年龄不超过60周岁；（三）掌握相关道路货物运输法规、机动车维修和货物装载保管基本知识；（四）经考试合格，取得相应的从业资格证件。\n　　申请参加经营性道路客货运输驾驶员从业资格考试的人员，应填写《经营性道路客货运输驾驶员从业资格考试申请表》，并提供下列材料：（一）身份证明及复印件；（二）机动车驾驶证及复印件；（三）申请参加道路旅客运输驾驶员从业资格考试的，还应当提供道路交通安全主管部门出具的3年内无重大以上交通责任事故记录证明。",
          isHot: 1,
          remark: null,
          sender: "热门问题",
          sort: 1,
          status: 0,
          title: "货运资格证如何办理？",
          updateBy: null,
          updateTime: "2024-08-30 16:30:25"
        },
        {
          content: "货运资格证如何办理？",
          createBy: null,
          createTime: "2024-08-30 14:00:40",
          id: 71,
          info: "您好，根据《交通运输部关于修改〈道路运输从业人员管理规定〉的决定》（中华人民共和国交通运输部令2019年第18号）的有关规定，申请参加经营性道路客货运输驾驶员从业资格考试的人员，应当向其户籍地或者暂住地设区的市级道路运输管理机构提出申请，经考试合格后，取得《中华人民共和国道路运输从业人员从业资格证》。\n　　经营性道路货物运输驾驶员应当符合下列条件：（一）取得相应的机动车驾驶证；（二）年龄不超过60周岁；（三）掌握相关道路货物运输法规、机动车维修和货物装载保管基本知识；（四）经考试合格，取得相应的从业资格证件。\n　　申请参加经营性道路客货运输驾驶员从业资格考试的人员，应填写《经营性道路客货运输驾驶员从业资格考试申请表》，并提供下列材料：（一）身份证明及复印件；（二）机动车驾驶证及复印件；（三）申请参加道路旅客运输驾驶员从业资格考试的，还应当提供道路交通安全主管部门出具的3年内无重大以上交通责任事故记录证明。",
          isHot: 1,
          remark: null,
          sender: "热门问题",
          sort: 1,
          status: 0,
          title: "货运资格证如何办理？",
          updateBy: null,
          updateTime: "2024-08-30 16:30:25"
        },
      ],
      tempObj: {},
      tempList: [
        {
          sender: '景区智能助手',
          content: '你好！我是景区智能助手，请描述您的问题吧'
        },
      ]
    }
  },
  created() {
    //获取热门路线
    // this.getHotTitle()
  },
  methods: {
    // 对话
    goTomessge() {
      let value = this.sms
      if (value !== '') {
        this.messages.push({sender: '我', content: value});
        this.sms = ''
        this.messages.push({sender: `景区智能助手`, content: `未找到对应详情呢 请描述详细些吧`});
        this.messageList()
      }

    },

    // 热门信息
    getHotTitle() {
      // getHotTitle().then((res) => {
      //   console.log(res, 'res')
      //   res.data = res.data.map((item) => {
      //     item.sender = '热门问题';
      //     item.content = item.title;
      //     return item;
      //   });
      //   console.log(res.data, 'data')
      //   this.hotList = this.hotList.concat(res.data)
      //   console.log(this.hotList, 'this.hotList')
      // })
    },

    // 点击回答问题
    handClick(item) {
      console.log(item, 'item')
      this.showHot = true;
      this.tempObj = item
    },

    //滑动到屏幕最底下
    messageList() {
      console.log('已执行')
      let divElement = document.querySelectorAll("#myDiv");
      divElement.scrollTop = divElement.scrollHeight;
    }
  }
};
</script>

<template>
  <div>
    <div class="myDiv" id="myDiv" ref="chatContainer">

      <!--热门消息-->
      <div class="chat-message-other hotlist">

        <!--消息上面-->
        <div style="display: block;padding: 10px 5px;">
          热门问题
        </div>

        <!--消息-->
        <div class="message-other-asWhole-right" v-for="(item, index) in hotList" :key="item.id">

          <!--热门消息-->
          <div v-if="item.sender === '热门问题'" class="message-other" @click="handClick(item)">
            <span style="color: #4252dd"> {{ index + 1 }} 、{{ item.content }}</span>
          </div>

        </div>

        <!--消息-->
        <div class="message-other-asWhole-right">
          <!--热门消息-->
          <div class="message-other marTop">
            <span>你好！景区智能助手，请描述您的问题吧</span>
          </div>
        </div>

      </div>


      <!--消息整体--><!--:style="{'padding-bottom':messages.length-1===index?'2rem':'none'}"-->
      <div :class="message.sender==='我'?'chat-message-me':'chat-message-other'"
           v-for="(message, index) in messages"
           :key="index"
      >

        <!--消息头像-->
        <div :class="message.sender==='我'?'message-me-asWhole-headPortrait':'message-other-asWhole-headPortrait'">
          <!--<img src="../../assets/img/if-barricade.png" class="examineeFace_logo_style">-->
        </div>

        <!--消息-->
        <div :class="message.sender==='我'?'message-me-asWhole-right':'message-other-asWhole-right'">

          <!--消息上面-->
          <div v-if="message.sender !=='热门问题'"
               :class="message.sender==='我'?'message-me-asWhole-top':'message-other-asWhole-top'">
            {{ message.sender }}
          </div>

          <!--消息内容-->
          <div v-if="message.sender!=='热门问题'" :class="message.sender==='我'?'message-me':'message-other'">
            <span v-html="message.content"></span>
          </div>

        </div>

      </div>
    </div>

    <!--输入框-->
    <div style="position: fixed;bottom: 0;width: 100%;height: 100px;background-color: black">
      <van-field
          v-model="sms"
          center
          clearable
          label=""
          placeholder="请输入您的问题"
      >
        <template #button>
          <van-button size="small" type="primary" @click="goTomessge">发送</van-button>
        </template>
      </van-field>
    </div>

    <!--弹出层-->
    <van-popup v-model="showHot" class="propObj">
      <div>
        <h4>
          {{ tempObj.title }}
        </h4>
        <div class="info">
          {{ tempObj.info }}
        </div>
      </div>
    </van-popup>
  </div>


</template>

<style scoped>

.info {
  font-size: 14px;
}

.propObj {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 20px;
}

.marTop {
  margin: 20px 0;
}

.myDiv {
  padding-bottom: 30vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.chat-message-other {
  display: flex;
  padding-left: 1rem;
  //padding-top: 1rem;
}

.chat-message-me {
  /*background-color: red;*/
  display: flex;
  padding-right: 1rem;
  padding-top: 1rem;
  flex-direction: row-reverse; /* 将子div的顺序反转 */

}

.message-me-asWhole-headPortrait {
  padding: 3px;
}

.message-other-asWhole-headPortrait {
  padding: 3px;
}

.message-me-asWhole-right {
  display: flex;
  flex-direction: column; /* 设置子元素垂直排列 */
  margin-left: 0.1rem;
}

.message-other-asWhole-right {
  display: flex;
  flex-direction: column; /* 设置子元素垂直排列 */
  margin-left: 0.1rem;
}

.message-me-asWhole-top {
  padding: 3px;
  /* font-size: 12px; */
  font-family: 微软雅黑;
  padding: 3px;
  color: rgba(134, 144, 156, 1);
  text-align: right;
}

.message-other-asWhole-top {
  padding: 3px;
  /* font-size: 12px; */
  font-family: 微软雅黑;
  padding: 3px;
  color: rgba(134, 144, 156, 1);
}

.message-me {
  background-color: rgba(242, 243, 245, 1);
  max-width: 280px;
  word-wrap: break-word; /* 处理英文单词换行 */
  word-break: break-all; /* 处理中文换行 */
  display: inline-block; /*将div元素转换为行内块元素*/
  width: auto; /* 宽度根据文本宽度自动调正*/
  padding: 6px 12px;
  border-radius: 4px;
}

.message-other {
  background-color: rgba(242, 243, 245, 1);
  max-width: 280px;
  word-wrap: break-word; /* 处理英文单词换行 */
  word-break: break-all; /* 处理中文换行 */
  display: inline-block; /*将div元素转换为行内块元素*/
  width: auto; /* 宽度根据文本宽度自动调正*/
  padding: 6px 12px;
  border-radius: 4px;
}


.hotlist {
  display: flex;
  flex-direction: column;
}

</style>
