<template>
  <div class="tabbar-container">
    <van-tabbar v-model="currentSelected" :inactive-color="color" :active-color="selectedColor" @change="onChange" route
      placeholder safe-area-inset-bottom>
      <template v-for="(item, index) in tabBars">
        <van-tabbar-item :to="item.name" :badge="item.badge" :dot="item.isShowRedDot" replace>
          <span>{{ item.text }}</span>
          <template #icon="props">
            <img :src="props.active ? item.selectedIconPath : item.iconPath" />
          </template>
        </van-tabbar-item>
      </template>
    </van-tabbar>
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from "vant";

export default {
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },
  props: {
    selected: { type: Number, default: 0 },

    color: { type: String, default: "#999999" },

    selectedColor: { type: String, default: "#666666" },

    tabBars: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      //是否选中
      active: 0,
      //当前选中
      currentSelected: this.selected,
    };
  },
  methods: {
    onChange(index) {
      this.$emit("onChange", index);
    },
  },
  created() {
    if (this.$route.path === "/Main" && this.tabBars.length) {
      this.$router.push(this.tabBars[this.currentSelected].name);
    }
  },
};
</script>

<style>
.tabbar-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
}
</style>
