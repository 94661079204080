<template>
  <div class="body">

  </div>
</template>

<script>

export default {
  data() {
    return {
    };
  },
  created() {
  },
  methods: {},
};
</script>

<style>

.itemTwo{
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 10px;
}

.itemTwo h2 , .itemTwo h4 {
  display: flex;
  justify-content: center;
}

.itemTwo div{
  margin: 5px 0;
}
.itemTwo div:last-child{
  margin-bottom: 50px;
}
.body {
  width: 100%;
}

.bakTwo {
  z-index: -1000;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: 100% 100%;
}


</style>
