<template>
  <div class="body">

    <!-- 搜索框 -->
    <div class="search">
      <van-icon name="search" size="20" color="#666666"></van-icon>
      <input class="input-class" type="text" placeholder="请输入景区名称"/>
      <button class="btn" @click="">搜索</button>
    </div>

    <div class="home_con">
      <div class="photo">
        <div>
          景点识别
        </div>
      </div>

      <div class="prevention">

        <div class="disease">
          <div>
            景点安全
          </div>
        </div>

        <div class="disease">
          <div>
            景点路线
          </div>
        </div>
      </div>

    </div>

    <div class="box-title">
      <div class="title">景点论坛</div>
    </div>

    <div class="box-card">
      <div class="box-item" v-for="(item,index) in itemList" :key="index">
        <div class="time">{{ item.createTime }}</div>
        <div class="species" v-if="item.createTime">景点</div>

        <div class="item-wenda">

          <div class="wenda" v-if="item.topicContent">
            <img src="../../assets/img/wen.png"/>
          </div>

          <div class="tWen">
            <div>
              <div>{{ item.topicContent }}</div>
              <img :src="item.topicImages" class="diseaseMap"/>
            </div>
          </div>

        </div>

        <div class="item-wendaTwo" v-if="item.replyList[0].cover">
          <div class="wenda">
            <img src="../../assets/img/da.png"/>
          </div>
          <div class="daan" v-if="item.replyList.length > 0">
            <div class="daanTitle">
              <img :src="item.replyList[0].cover" alt=""/>
              <div>{{ item.replyList[0].userName }}</div>
            </div>
            <div class="con">{{ item.replyList[0].replyContent }}</div>
          </div>
        </div>

        <div class="item-records">
          {{ item.replySize }}条回答
          <img src="../../assets/img/right.png" alt=""/>
        </div>

      </div>
    </div>

    <img src="../../assets/img/flow.png" alt="背景图" class="bak">
  </div>
</template>

<script>
export default {
  data() {
    return {
      itemList: [
        {
          createBy: "微信用户",
          createTime: "2024-08-19 16:40",
          id: 144,
          replySize: 5,
          status: 1,
          topicContent: "这种青虫是什么虫的幼虫？",
          userId: 47,
          topicImages: ["https://yqcy-1300645565.cos.ap-beijing.myqcloud.com/yqcy/25631724056802210.jpg"],
          replyList: [
            {
              cover: "https://thirdwx.qlogo.cn/mmopen/vi_32/POgEwh4mIHO4nibH0KlMECNjjGxQUq24ZEaGT4poC6icRiccVGKSyXwibcPq4BWmiaIGuG1icwxaQX6grC9VemZoJ8rg/132",
              createBy: "微信用户",
              createTime: "2024-08-21 11:58",
              id: 114,
              isTrue: 0,
              replyContent: "是斜纹夜蛾的幼虫吗？",
              replyId: null,
              replyImg: null,
              replyStatus: 0,
              replyType: 0,
              targetId: null,
              topicId: 144,
              updateBy: null,
              updateTime: "2024-08-21 11:58",
              userId: 47,
              userName: "微信用户",
            },
            {
              cover: "https://thirdwx.qlogo.cn/mmopen/vi_32/POgEwh4mIHO4nibH0KlMECNjjGxQUq24ZEaGT4poC6icRiccVGKSyXwibcPq4BWmiaIGuG1icwxaQX6grC9VemZoJ8rg/132",
              createBy: "微信用户",
              createTime: "2024-08-21 11:58",
              id: 114,
              isTrue: 0,
              replyContent: "是斜纹夜蛾的幼虫吗？",
              replyId: null,
              replyImg: null,
              replyStatus: 0,
              replyType: 0,
              targetId: null,
              topicId: 144,
              updateBy: null,
              updateTime: "2024-08-21 11:58",
              userId: 47,
              userName: "微信用户",
            },
            {
              cover: "https://thirdwx.qlogo.cn/mmopen/vi_32/POgEwh4mIHO4nibH0KlMECNjjGxQUq24ZEaGT4poC6icRiccVGKSyXwibcPq4BWmiaIGuG1icwxaQX6grC9VemZoJ8rg/132",
              createBy: "微信用户",
              createTime: "2024-08-21 11:58",
              id: 114,
              isTrue: 0,
              replyContent: "是斜纹夜蛾的幼虫吗？",
              replyId: null,
              replyImg: null,
              replyStatus: 0,
              replyType: 0,
              targetId: null,
              topicId: 144,
              updateBy: null,
              updateTime: "2024-08-21 11:58",
              userId: 47,
              userName: "微信用户",
            },
            {
              cover: "https://thirdwx.qlogo.cn/mmopen/vi_32/POgEwh4mIHO4nibH0KlMECNjjGxQUq24ZEaGT4poC6icRiccVGKSyXwibcPq4BWmiaIGuG1icwxaQX6grC9VemZoJ8rg/132",
              createBy: "微信用户",
              createTime: "2024-08-21 11:58",
              id: 114,
              isTrue: 0,
              replyContent: "是斜纹夜蛾的幼虫吗？",
              replyId: null,
              replyImg: null,
              replyStatus: 0,
              replyType: 0,
              targetId: null,
              topicId: 144,
              updateBy: null,
              updateTime: "2024-08-21 11:58",
              userId: 47,
              userName: "微信用户",
            },
          ]
        }
      ]
    };
  },

  created() {
  },

  methods: {},
};
</script>

<style lang="less" scoped>

.box-card {
  margin-top: 20px;
}

.box-title .title {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  margin: 20px 0 0 20px;
}

.box-item {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  padding: 5px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin: 0 0 10px 0;
}

.box-item .time {
  font-weight: 400;
  font-size: 16px;
  color: #999999;
}

.box-item .species {
  background: #2DC26E;
  font-size: 24rpx;
  color: #FFFFFF;
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 10px;
  border-radius: 0px 0 0 10px;
}

.wenda {
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
}

.wenda text {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.wenda img {
  width: 20px;
  height: 20px;
  margin: 4px 7px 2px 0;
}

.item-wenda {
  display: flex;
  flex-direction: row;
  margin: 5px 0 0 0;
}

.item-wendaTwo {
  display: flex;
  flex-direction: row;
  margin: 5px 0 10px 0;
}

.tWen {
  font-weight: 500;
  font-size: 28rpx;
  color: #333333;
}

.tWen img {
  width: 80px;
  height: 100px;
  margin: 8px 10px 5px 0;
}

.daan {
  font-weight: 400;
  font-size: 24rpx;
  color: #333333;
  display: flex;
  flex-direction: column;
  margin-top: 8rpx;
}

.daanTitle{
  display: flex;
  flex-direction: row;
  margin-top: 4px;
}

.daanTitle img{
  height: 20px;
  width: 20px;
  border-radius: 90px;
  margin-right: 10px;
}


.daan image {
  display: flex;
  align-items: center;
  width: 20px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.daan .con {
  font-weight: 400;
  font-size: 28rpx;
  color: #333333;
  margin-top: 5rpx;
}

.diseaseMap {
  height: 10px;
  margin: 5px 0 0 0;
  border-radius: 15rpx;
}

.item-records {
  position: relative;
  width: 100%;
  bottom: 5px;
  left: 5px;
  font-weight: 400;
  font-size: 16px;
  color: #999999;
}

.item-records img {
  width: 10px;
  height: 10px;
}

.box-title .title {
  font-size: 21px;
  font-weight: bold;
  color: #333333;
  margin: 15px 0 0 0;
}

.disease {
  width: 100%;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: #4252dd 1px solid;
}

.prevention {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.photo {
  width: 50%;
  background-color: #4252dd;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home_con {
  width: 100%;
  height: 170px;
  background-color: #38bc9d;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  border-radius: 30px;
  overflow: hidden;
}

.search {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 5px;
  background: transparent;
  border-radius: 44px;
  border: 1px solid #666666;
  margin-top: 30px;
}

.search .input-class {
  display: flex;
  justify-content: space-between;
  flex: 1;
  margin-left: 16px;
  border-radius: 30px;
  padding: 5px;
  border: none;
}

.search .btn {
  font-size: 12px;
  color: #FFFFFF;
  padding: 5px;
  //width: 120px;
  font-weight: 500;
  border: none;
  border-radius: 30px;
  color: #666666;
}

.body {
  box-sizing: border-box;
  padding: 0 20px;
  width: 100%;
  height: 100%;
}

.bak {
  z-index: -1000;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

</style>
