<template>
  <div>
  </div>
</template>

<script>
import { login } from "@/api/login";
import { setToken } from "@/utils/auth";
export default {
  name: "Login",
  components: {},
  data() {
    return {
      account: "admin",
      pwd: "yqcy123",
    };
  },
  methods: {
    toLogin() {



      this.$toast.loading({
        duration: 0,
        message: '正在登录...',
      });
      this.$router.replace({ name: "Module1" });
      this.$toast.clear();
      // login(this.account, this.pwd).then(res => {
      //   this.$toast.success('登录成功');
      //   setToken(res.token)
      //   this.$router.replace({ name: "Main" });
      //   this.$toast.clear();
      // })


    }
  },
  created() {
    this.$router.replace({ name: "Module1" });
  },
};
</script>

<style>
.img-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
}

.logo {
  width: 100px;
  height: 100px;
  /* border-radius: 50px; */
  margin-top: 20px;
}

.text {
  width: 100vw;
  text-align: center;
  margin: 30px 0;
}

.input-bg {
  margin: 15px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  padding-bottom: 2px;
}
</style>
